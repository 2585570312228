/**
 * Component classes registered by plugins.
 *
 */

@import "print.css";

@import "tables.css";

@import "prism.css";

@tailwind components;

input {
  padding: 5px !important;
}
.sign-in-page label {
  margin-bottom: 5px;
}
.create-partner-div label,
.create-contact-div label {
  margin-left: 6px;
}
.pac-container {
  z-index: 99999;
}
.contact-list-table-heading {
  font-size: 14px !important;
}
.create-contact-address-search .pac-target-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 360px;
  padding: 10px !important;
}
.contact-list-page {
  margin-top: 20px;
}
.wi-70 {
  width: 70%;
}
.wi-60 {
  width: 60%;
}
.wi-40 {
  width: 40%;
}
.wi-30 {
  width: 30%;
}
.google-map-div {
  padding: 0px 50px;
}
.map-container {
  height: 100%;
  width: 100%;
}
.hide-google-address-search {
  /* position: absolute; */
  /* bottom: 0px; */
}
.contact-details-header-data {
  text-align: center;
}
.show-google-address-search {
}
.create-job-with-contact {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-bottom: 10px;
}
.primary-color {
  color: #0284c7;
}
.grey-color {
  color: #6b7280;
}
.login-sub-heading {
  font-size: 3.4rem;
  margin-top: 10px;
}
.signup-header {
  font-size: 4.2rem;
}
.user-profile-content {
  margin-left: 15px;
  margin-top: 16px;
  width: 100%;
}
.user-border-bottom {
  border-bottom: 1px solid #374151 !important;
}
.form-input-content {
  width: 100%;
}
.user-profile-dots {
  float: right;
  padding: 0px;
  top: -30px;
}
.vertical-navigation {
  margin-top: 25px;
}
.vertical-navigation .active {
  background-color: #0369a1 !important;
}
.top-toolbar {
  background-color: #1f2937 !important;
}
.white-color {
  color: white !important;
}
.white-color svg {
  color: #ffffff !important;
}
.text-center {
  text-align: center !important;
}
.toolbar-color {
  color: #111827 !important;
  font-weight: bold;
  font-size: 28px;
}
.jobs-list-tab {
  flex-direction: row;
}
.bg-white {
  background-color: #ffffff;
}
.bottom-border {
  border-bottom: 1px solid #eeeeee;
}
.job-details-card {
  background-color: #075985;
  color: #f3f4f6;
}
.contact-details-edit-icon {
  margin-top: 20px;
  height: 14px;
  width: 14px;
  min-width: 14px !important;
  min-height: 14px !important;
  cursor: pointer;
}
.contact-detail-field {
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px;
  margin-bottom: 5px;
}
.create-job-google-address-search {
  border-radius: 4px;
  position: relative;
  background-color: #fcfcfb;
  border: 1px solid #ced4da;
  font-size: 16px;
}
.job-details-top {
  padding: 5px;
}
.flex-35 {
  flex: 1 1 35% !important;
}
.flex-65 {
  flex: 1 1 65% !important;
}
.flex-70 {
  flex: 1 1 70% !important;
}
.flex-60 {
  flex: 1 1 60% !important;
}
.flex-40 {
  flex: 1 1 40% !important;
}
.padding-right-zero {
  padding-right: 0px;
}
.job-details-primary-button,
.job-details-primary-button:hover {
  background-color: #bae6fd;
  color: #0c4a6e;
  border-radius: 6px;
}
.job-details-secondary-button {
  background-color: #075985;
  color: #e5e7eb;
  border-radius: 6px;
  border: 1px solid #bae6fd;
}
.font-bold {
  font-weight: bold;
}
.job-detail-bottom-content {
  /* padding-left: 24px; */
  padding-top: 20px;
  font-size: 14px;
  color: #1f2937;
}
.mobile-job-detail-bottom-content {
  width: 50%;
  float: left;
}
.jobs-list-header {
  padding: 15px 10px;
  border-radius: 6px;
}
.jobs-list-header button {
  background-color: #059669;
}
.jobs-list-header button:hover {
  background-color: #059669;
}
.jobs-list-content {
  padding-left: 15px;
  padding-right: 15px;
}
.job-list-tables,
.partners-list-tables {
  padding-top: 10px;
}
.partners-list-table-heading span {
  font-size: 12px !important;
}
.dialog-title {
  text-align: center;
  width: 84%;
}
.dialog-button-continue {
  background-color: #0284c7;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}
.dialog-button-continue:hover {
  background-color: #0284c7;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}
.invoice-paid-button:hover {
  background-color: #0284c7;
  color: #f9fafb;
}
.dialog-button-back {
  background-color: #e0f2fe;
  color: #0284c7;
  border-radius: 6px;
}
.dialog-button-back:hover {
  background-color: #e0f2fe;
  color: #0284c7;
  border-radius: 6px;
}
.dialog-button-invite {
  background-color: #059669;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
  padding-left: 20px;
}
.dialog-button-invite:hover {
  background-color: #059669;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}
.form-input-label:hover,
.form-input-label.Mui-focused {
  color: #374151 !important;
}
.create-job-type-label {
  line-height: 30px !important;
}
.create-job-step1,
.create-job-step2 {
  margin-bottom: 30px;
}
.dialog-buttons {
  padding: 20px 30px 20px 30px;
  display: inline;
}
.sci-dialog .MuiDialog-paper {
  width: 100%;
}
.sci-dialog .MuiDialogContent-root {
  padding: 20px 50px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  align-items: center;
  display: flex;
  justify-content: center;
}
.sci-dialog .MuiDialogContent-root .MuiFormControl-root {
  width: 85%;
}
.user-profile-dialog .MuiDialog-container .MuiPaper-root {
  background-color: #ffffff !important;
  color: rgb(17, 24, 39) !important;
}
.user-profile-icon-button {
  color: rgba(0, 0, 0, 0.54);
}
.user-profile-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.user-profile-page-icon {
  float: left;
  margin-right: 2px;
  margin-top: -2px;
}
.no-profile-image {
  width: 48px;
  height: 48px;
}
.display-profile-image {
  float: left;
  width: 48px;
  height: 48px;
  border-radius: 100px;
}
.profile-image-upload-btn {
  border: 1px solid #ccc;
  margin-left: 22px;
  border-radius: 10px;
  margin-top: 2px;
}
.form-input-label {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #374151;
  position: relative;
  margin-top: 5px;
}
.job-step-dots {
  /* width: 12px; */
  margin: 5px 5px;
  background-color: #e5e7eb;
  padding: 2px;
}
.dialog-steps {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
.margin-top-20 {
  margin-top: 20px;
}
.step-text {
  font-size: 12px;
  margin-right: 10px;
  font-weight: bold;
}
.mobile-create-job-dialog-content {
  padding: 0px !important;
}
.web-create-job-dialog-content {
  padding: 20px 50px !important;
}
.web-create-job-dialog-content .MuiFormControl-root {
  /* margin-top: 20px; */
}
.mobile-create-job-dialog-content label + .MuiInputBase-root {
  width: 100% !important;
}
.current-step {
  background-color: #0284c7;
  box-shadow: 0px 0px 8px 3px #bae6fd;
}
.mobile-create-job-dialog-content .step-text {
  font-size: 10px !important;
  margin-right: 13px !important;
}
.web-create-job-dialog-content .step-text {
  font-size: 13px !important;
  margin-right: 13px !important;
}
.web-create-job-dialog-content .job-step-dots {
  padding: 3px !important;
}
.create-job-radio.Mui-checked svg:nth-of-type(1) {
  color: #10b981 !important;
}
.create-job-radio.Mui-checked svg:nth-of-type(2) {
  color: #10b981 !important;
}
.create-job-radio-label-selected {
  background-color: #a7f3d0;
  border-radius: 6px;
}
.create-job-radio-label,
.create-job-radio-label:hover {
  color: #374151;
}
.web-create-job-dialog-content .create-job-step3 {
  margin: auto -50px !important;
}
.form-field-error {
  color: red;
  margin: 5px 0px 0px;
  font-size: 14px;
}
.field-hide {
  display: none;
}
.jobs-list-table th {
  background-color: #f9fafb !important;
  color: #9ca3af;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}
.jobs-list-table th:nth-of-type(1) {
  border-radius: 6px 0px 0px 0px;
  border-left: 1px solid #e5e7eb;
  padding-left: 20px;
}
.jobs-list-table th:nth-last-child(1) {
  border-radius: 0px 6px 0px 0px;
  border-right: 1px solid #e5e7eb;
}
.mobile-job-customer-name {
  color: #111827;
  font-weight: 500;
}
.mobile-job-address {
  color: #374151;
}
.jobs-list-table td {
  border-bottom: 1px solid #e5e7eb;
}
.job-list-table-row td:nth-of-type(1) {
  border-left: 1px solid #e5e7eb;
  padding-left: 20px;
}
.job-list-table-row td:nth-last-child(1) {
  border-right: 1px solid #e5e7eb;
}
.job-detail-heading {
  font-size: 16px;
  color: #1f2937;
  margin-left: 0px !important;
}
.job-details-tab,
.job-detail-details {
  color: #1f2937;
}
.job-detail-main {
  background-color: #f9fafb;
  min-height: 100%;
}
.job-detail-tabs .MuiTabs-scroller {
  margin-bottom: 15px !important;
  margin-left: 15px;
}
.job-detail-tabs .MuiTabs-flexContainer button {
  padding-top: 0px !important;
}
.job-detail-tabs .MuiTabs-flexContainer .Mui-selected {
  background-color: #e0f2fe;
  border-radius: 8px;
}
.job-detail-tabs .MuiTabs-flexContainer .Mui-selected .job-detail-tab-icon svg {
  color: #0ea5e9;
}
.job-detail-tabs
  .MuiTabs-flexContainer
  .Mui-selected
  .job-detail-mobile-tab-icon
  svg {
  color: #0ea5e9;
}
.job-detail-tabs-list {
  display: inline;
}
.job-detail-tab-icon {
  float: left;
  padding-top: 14px;
}
.job-detail-mobile-tab-icon svg {
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
}
.job-detail-mobile-tab-icon {
  margin-top: 5px;
}
.job-detail-tab-heading {
  float: right;
  padding-top: 14px;
  padding-left: 5px;
}
.jobs-list-table-heading {
  font-size: 12px;
}
.web-jobs-list-table-heading {
  font-size: 14px;
}
.mobile-partners-list-table-heading {
  font-size: 12px;
}
.web-partners-list-table-heading {
  font-size: 14px;
}
.mobile-job-assignment-content {
  position: absolute;
  top: 50px;
  background-color: #f9fafb;
}
.mobile-job-assignment-content .job-details-card {
  margin: 15px;
}
.mobile-detail-content {
  /* position: absolute;
    top: 360px; */
  background-color: #f9fafb;
}
.quotes-accordion {
  background-color: #f9fafb;
}
.request-quote-dialog .MuiDialogContent-root {
  width: 100%;
  padding: 0px;
  border-top: 0px;
}
.request-quote-dialog-tabs-div {
  width: 100%;
}
.user-profile-dialog-tabs-div {
  width: 100%;
  margin: auto;
}
.request-quote-dialog-tabs-div .MuiTabs-flexContainer,
.user-profile-dialog-tabs-div .MuiTabs-flexContainer {
  justify-content: center;
}
.web-request-partners-list {
  padding: 0px 85px 20px 85px;
}
.web-request-partners-list img {
  height: 36px;
  width: 36px;
  border-radius: 30px;
}
.mobile-request-partners-list img {
  height: 32px;
  width: 32px;
  border-radius: 30px;
}
.clear {
  clear: both;
}
.mobile-request-partners-list .request-partner {
  padding: 20px;
}
.web-request-partners-list .request-partner {
  padding: 20px;
}
.web-request-partners-list .requester-name {
  padding: 0px 10px;
  max-width: 60%;
}
.mobile-request-partners-list .requester-name {
  padding: 0px 10px;
  max-width: 53%;
}
.requester-name-label {
  color: #111827;
  font-size: 14px;
  font-weight: 500;
}
.requester-email-label {
  color: #6b7280;
  font-size: 14px;
}
.request-partner {
  border-top: 1px solid #e2e8f0;
}
.request-quote-dialog h2 {
  padding-bottom: 0px;
}
.requested-button,
.requested-button:hover {
  border: 1px solid #d1d5db;
  color: #374151;
  background-color: #ffffff;
  border-radius: 6px;
  font-weight: 500 !important;
}
.request-button,
.request-button:hover {
  background-color: #0284c7 !important;
}
.cursor-auto {
  cursor: auto;
}
.quote-list-quote-status {
  background-color: #bae6fd;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  color: #1f2937;
  line-height: 16px;
  font-weight: 500;
}
.quote-request-card {
  margin-right: 15px !important;
  margin-bottom: 20px;
  border: 1px solid #e5e7eb;
}
.quote-request-card {
  margin-right: 15px;
  /* border: 1px solid #E5E7EB; */
}
.quote-list-second-line {
  margin-bottom: 15px;
  margin-top: 5px;
}
.quote-list-quote-id {
  margin-right: 20px;
  color: #4b5563;
}
.quote-list-hash {
  color: #9ca3af;
}
.quote-list-quote-date {
  color: #4b5563;
}
.quote-list-quote-date svg,
.quote-list-quote-amount svg {
  margin-right: 5px;
  margin-top: 1px;
}
.quote-list-decline-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  color: #374151;
}
.quote-list-approve-button,
.quote-list-approve-button:hover {
  background-color: #10b981;
  color: #ffffff;
}
.pending-quote-badge {
  background-color: #f3f4f6;
  color: #1f2937;
  border-radius: 10px;
  padding: 5px;
}
.received-quote-badge {
  background-color: #0ea5e9;
  color: #f9fafb;
  border-radius: 10px;
  padding: 5px;
}
.quote-list-account-name {
  color: #0c4a6e;
  font-size: 16px;
  font-weight: 500;
}
.web-detail-content {
  background-color: #f9fafb;
  padding-right: 0px;
  flex: 1 1 28% !important;
}
.quotes-list-accordion-details {
  padding-left: 5px;
}
.quotes-list-tab .Mui-expanded {
  margin: 12px 0px;
  min-height: min-content;
}
.empty-partners-list {
  padding: 20px;
  text-align: center;
}
.web-request-partners-list .empty-partners-list {
  padding-bottom: 0px;
}
.display-none {
  display: none !important;
}
.max-width-200 {
  max-width: 200px;
}
.max-width-150 {
  max-width: 150px;
}
.max-width-180 {
  max-width: 180px;
}
.primary-button,
.primary-button:hover {
  background-color: #0369a1 !important;
  color: white !important;
}
.add-files-btn {
  float: right;
  margin-bottom: 20px;
}
.job-detail-attachment-link {
  background: none !important;
  border: none !important;
  text-decoration: underline !important;
}
.max-width-240 {
  max-width: 240px;
}
.create-partner-input input {
  padding: 5px;
}
.job-feed-sort-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
}
.conversation-profile-pic {
  height: 48px;
  width: 48px;
  border-radius: 100%;
}
.conversation-no-profile {
  height: 48px;
  width: 48px;
  border-radius: 100%;
}
.dashboard-profile-pic {
  height: 38px;
  width: 38px;
  border-radius: 100%;
}
.dashboard-no-profile {
  height: 38px;
  width: 38px;
  border-radius: 100%;
}
.conversation-reply-profile-pic {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.conversation-reply-no-profile {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.conversation-reply-pic div {
  margin: 0px 5px;
}
.conversation-image-div {
  margin-right: 15px;
}
.each-conversation {
  border-top: 1px solid #e5e7eb;
  padding: 15px 0px;
}
.conversation-user-name {
  color: #111827;
  font-size: 14px;
  font-weight: 600;
}
.conversation-account-name {
  color: #6b7280;
  font-size: 12px;
}
.conversation-message {
  margin-top: 5px;
  color: #111827;
  font-size: 14px;
}
.conversation-created-time {
  color: #374151;
  font-size: 12px;
}
.conversation-created-time-right {
  float: right;
  position: absolute;
  right: 15px;
}
.conversation-reply-link {
  margin-top: 5px;
  font-size: 12px;
  color: #4b5563;
  margin-bottom: 5px;
}
.conversation-reply-link svg {
  float: left;
}
.conversation-reply-count {
  color: #0284c7;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}
.feed-conversations {
  margin-top: 15px;
}
.create-conversation .MuiFormControl-root {
  background-color: #ffffff;
}
.assign-partner-label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 8px;
}
.assign-partner-name .MuiSelect-select {
  padding: 0px;
}
.assign-job-dialog-div {
  margin: 20px;
}
#assign-partner-name {
  padding-top: 7px;
  padding-left: 5px;
}
.assign-partner-name {
  width: 96% !important;
}
.jobs-table-pagination .MuiTablePagination-select {
  padding-top: 15px;
}
.job-details-back {
  position: absolute;
  color: #9ca3af;
}
.job-details-heading {
  margin: auto;
  align-items: center !important;
}
.job-detail-fuse-carded .fuse-carded-container {
  border-radius: 0px;
  box-shadow: none;
}
.job-detail-fuse-carded .fuse-carded-container .FusePageCarded-contentWrapper {
  overflow: hidden !important;
}
.feed-list-heading {
  color: #1f2937;
  float: left;
  margin-top: 5px;
}
.mobile-job-feed-sort-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  float: right;
}
.mentions-input-text__control input {
  top: -40px !important;
  left: 20px !important;
  z-index: 9 !important;
}
.create-conversation-input {
  z-index: 9999;
}
.mentions-input-text {
  width: 95%;
}
.mentions-input-text__highlighter {
  background-color: #f9fafb;
}
.jobs-list-page .fuse-carded-container {
  border-radius: 0px !important;
}
.mobile-jobs-list-fitlers {
  float: right;
}
.web-jobs-list-fitlers {
  float: right;
  width: 20%;
}
.mobile-jobs-list-fitlers .jobs-list-filters {
  max-width: 130px;
  margin: 0px;
}
.web-jobs-list-fitlers .jobs-list-filters {
  margin: 0px;
  float: right;
}
.mobile-jobs-list-header {
  padding: 15px 0px !important;
}
.each-attachment {
  padding: 10px 5px;
  border-bottom: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}
.attachment-icons {
  margin: 0px 5px;
}
.web-request-proposal-dialog-content {
  /* border-bottom: none !important; */
}
.each-attachment:first-child {
  border-top: 1px solid #e5e7eb;
  border-radius: 4px 4px 0px 0px;
}
.each-attachment:last-child {
  border-radius: 0px 0px 4px 4px;
}
.attachment-download-icon {
  color: #059669;
}
.attachment-delete-icon {
  color: #f46a36;
}
.attachments-list-div {
  margin-top: 10px;
}
.attachments-header {
  font-size: 16px;
  font-weight: 500;
}
.attachment-left-div {
  max-width: 80%;
}
.user-profile-content .account-name {
  max-width: 90%;
}
.account-name .username {
  max-width: 90%;
}
.jobs-list-filters .MuiSelect-select {
  padding-bottom: 10px;
}
.quoteAmountLabel {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
}
.quoteAmountField {
  background-color: white;
}
.submitQuote,
.submitQuote:hover {
  width: 100%;
  border-radius: 6px;
  margin: 10px 0px;
  background-color: #059669;
}
.declineQuote,
.declineQuote:hover {
  width: 100%;
  border-radius: 6px;
  background-color: #075985;
  border: 2px solid #0369a1;
}
.quoteAmountDisplay {
  justify-content: center;
}
.quoteAmountDollor {
  color: white;
  margin-right: 5px;
}
.quoteEditButton,
.quoteEditButton:hover {
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: transparent;
}
.page-heading {
  font-weight: 700;
  font-size: 32px;
  margin-right: 30px;
}
.add-attachment-label {
  color: #6b7280;
  font-size: 16px;
}
.add-attachment-icon {
  color: #9ca3af;
  margin-right: 10px;
  font-size: 24px;
}
.update-job-details-button-section {
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  background-color: rgba(231, 231, 231, 0.7) !important;
  color: white !important;
  text-align: center !important;
  padding: 15px !important;
}
.show-update-button {
  display: block;
}
.hide-update-button {
  display: none !important;
}
.update-job-detail-button-cancel {
  background-color: white;
  color: #1e293b;
}
.update-job-detail-button-update {
  margin-left: 10px;
}
.job-detail-edit-text {
  border: 1px solid #1f2937;
  border-radius: 5px;
  margin-top: -10px;
  /* margin-left: 10px; */
}
.job-detail-edit-text-main {
  margin-left: 10px;
}
.google-map-div {
  margin-left: 25px;
}
.contact-details-left {
  width: 95%;
}
.contact-details-field {
  width: 105%;
}
.margin-auto {
  margin: auto;
}
.contact-search-label {
  display: inline-block;
  text-decoration: underline;
}
.contact-search-address {
  display: inline-block;
  /* margin-top: -10px; */
  font-size: 12px;
  color: rgb(107, 114, 128);
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}
.contact-details-field .pac-target-input {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}
.contact-search-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin: 0px;
  padding: 0px;
}
.contact-search-item-data {
  flex: 1 1 auto;
  min-width: 0;
  margin: 0px;
  padding: 0px;
}
.create-job-step1 .MuiAutocomplete-root,
.create-job-step1
  .MuiAutocomplete-root
  .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 100% !important;
}
.contact-detail-edit-field {
  margin-top: 5px;
  margin-left: 0px;
}
.edit-job-status,
.edit-scheduled-status {
  width: 100%;
}
.edit-job-status .MuiSelect-select,
.edit-scheduled-status .MuiSelect-select {
  padding: 8px 0px 0px 10px !important;
}
.new-job-button {
  float: left;
}
.job-search-filter-box {
  margin-left: 40px;
  margin-top: 0px;
  width: 33%;
}
.jobs-page-heading {
  width: 7%;
}
.job-page-new-job-button {
  width: 13%;
  max-width: 140px;
}
.jobs-page-search-filter-box {
  width: 75%;
}
.jobs-list-page-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56%;
}
.roofing-jobs-list-page-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
}
.roofing-job-search-filter-box {
  margin-left: 40px;
  margin-top: 0px;
  width: 70%;
}
.mobile-job-search-filter-box {
  /* margin-left: 7px; */
  margin-top: 0px;
  width: 100%;
  /* margin: 10px 40px !important; */
  /* padding-right: 20px;
    padding-left: 20px; */
  margin-top: 20px;
}
.job-search-filter-box .MuiTextField-root,
.mobile-job-search-filter-box .MuiTextField-root,
.jobs-page-search-filter-box .MuiTextField-root,
.roofing-job-search-filter-box .MuiTextField-root {
  margin-top: 0px;
}
.jobs-search-divider {
  margin-left: 0px;
}
.create-post-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.create-post-input-div {
  float: left;
  width: 80%;
  margin-left: 20px;
  margin-top: 4px;
  background-color: white;
  /* border-radius: 46px; */
  border-radius: 50px;
}
.create-conversation-post {
  /* margin-top: 20px; */
}
.job-datepicker-image {
  float: right;
  height: 24px;
  width: 24px;
  margin-right: 35px;
}
.show-update-job-date {
  display: block !important;
  position: fixed !important;
  left: 280px !important;
  bottom: 0 !important;
  width: 85% !important;
  background-color: rgba(231, 231, 231, 0.7) !important;
  color: white !important;
  text-align: center !important;
  padding: 15px !important;
  right: 0px;
  margin-right: 0px;
}
.jobs-datepicker-cancel {
  color: #0e74d5 !important;
  margin-right: 10px !important;
  background-color: white !important;
  cursor: pointer;
}
.jobs-datepicker-update {
  background-color: #1976d2;
  cursor: pointer;
}
.hide-update-job-date {
  display: none !important;
}
.create-conversation-input-div .MuiInputBase-root {
  border-radius: 50px;
}
.create-conversation-input-div input {
  padding-left: 15px !important;
}
.mobile-job-results {
  width: 92% !important;
}
.mobile-roofing-jobs-list-header {
  padding: 0px;
  padding-top: 20px;
}
.roofing-jobs-list-filters {
  width: 100% !important;
  margin: 0px !important;
}
.show-job-search-results {
  width: 40%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}
.mobile-show-job-search-results {
  width: 92%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}
.roofing-show-job-search-results {
  width: 44%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}
.hide-job-search-results {
  width: 32%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: none;
  z-index: 9999;
  position: absolute !important;
  background-color: white;
}
.create-conversation-input-div .Mui-focused fieldset {
  border: 1px solid silver !important;
}
.conversation-content-div {
  width: 70%;
}
.conversation-replies-list {
  margin-top: 10px;
}
.conversation-replies-list .conversation-content-div {
  width: 60% !important;
}
.mention-highlight {
  color: #4a90e2;
}
.text-decoration-underline {
  text-decoration: underline;
}
.jobs-search-result-item span {
  text-decoration: underline;
}
.jobs-search-result-item:hover {
  background-color: #ccc;
}
.quote-list-table-body {
  background-color: white;
}
.jobs-list-page-content
  .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile {
  display: none;
}
.jobs-list-filters .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input,
.web-jobs-list-fitlers
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
  padding: 10px;
  padding-top: 12px;
  padding-left: 20px;
  padding-bottom: 5px;
}
.create-job-step3-content {
  /* margin-top: 20px; */
}
.roof-work-type-dd,
.job-type-dd,
.roof-job-type-dd {
  margin-top: 0px !important;
  width: 100%;
}
.mobile-sign-in-page .-top-125 {
  top: -125px;
}
.mobile-sign-in-page-heading {
  font-size: 38px;
  margin-top: 20px;
}
.mobile-sign-in-page .login-sub-heading {
  font-size: 30px;
}
.mobile-sign-in-page {
  max-height: 275px;
}
.mobile-forgot-password {
  float: right;
  margin-top: 10px;
}
.forgot-password-input input {
  padding: 10px !important;
}
.web-profile-update {
  padding: 0px 85px 20px 85px;
}
.text-align-right {
  text-align: right;
}
.add-proposal-btn {
  margin-bottom: 10px;
}
.post-dialog-content {
  overflow: visible;
}
.create-post-dialog .MuiDialog-paper.MuiPaper-root {
  overflow: visible;
}
.create-post-account-name {
  font-size: 11px;
}
.jobs-list-table-row td {
  max-width: 250px;
}
.min-height-44 {
  min-height: 44px;
}
.create-document-label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 5px;
}
.create-document-dialog-div .MuiFormControl-root {
  margin: 5px;
  width: 97% !important;
}
.assign-partner-label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 8px;
}
.create-document-name .MuiSelect-select {
  padding: 0px;
}
.create-document-field,
#document-type-name,
#create-document-partner {
  padding-top: 7px;
  padding-left: 5px;
  padding-bottom: 0px;
}
.create-document-field {
  width: 96% !important;
}
.request-document-heading {
  margin-bottom: 30px;
  font-size: 16px;
}
.partner-document-button {
  margin-top: 8px;
}
.white-button {
  background-color: #fff;
  color: rgb(3, 105, 161);
  border: 1px solid #0284c7;
}
.MuiDialogContent-root.web-add-document-dialog-content .MuiFormControl-root,
.MuiDialogContent-root.web-create-job-dialog-content .MuiFormControl-root {
  margin: auto;
}
.attachments-list-grid {
  padding-top: 0px !important;
}
.dashboard-single-update {
  border-bottom: 1px solid #ccc;
  padding: 15px;
}
.job-updates-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}
.single-update:last-child {
  border-bottom: none;
}
.update-sub-text {
  color: #6b7280;
  font-size: 13px;
  margin-top: 2px;
}
.update-text {
  font-size: 14px;
}
.dashboard-updates-div {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 25px;
  border-radius: 10px;
}
.dashboard-updates-icon {
  color: #3b8f73;
}
.dashboard-updates-icon-bg {
  background-color: #a7f3d0;
}
.dashboard-updates-heading {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 20px;
}
.dashboard-updates-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.dashboard-content-div {
  margin-left: 20px;
  max-width: 80%;
}
.invoice-action-buttons {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}
.invoice-add-button {
  width: 300px;
  color: #ccc;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

/* Upload Attachment */
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.add-attachment-dialog {
}
.request-document-tabs {
  margin-bottom: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.height-50 {
  height: 50px;
}
.view-document-link {
  text-decoration: underline !important;
  background: none !important;
}
.attachment-download-icon-link {
  float: left;
  background: none !important;
  text-decoration: none;
  border: none;
}
.float-right {
  float: right;
}
.attachments-link {
  cursor: pointer;
}
.job-page-quotes-list {
  max-height: 400px;
  margin-top: 15px;
}
.job-details-edit-icon {
  height: 14px;
  width: 14px;
  min-width: 14px !important;
  min-height: 14px !important;
  cursor: pointer;
}
.job-details-edit-div {
  justify-content: space-between;
}
.dialog-button-continue.Mui-disabled {
  background-color: #ccc;
}
.uploaded-files-heading {
  font-weight: bold;
  margin-bottom: 10px;
}
.empty-uploaded-files {
  text-align: center;
  margin-top: 20px;
}
.empty-uploaded-files-message {
  margin-top: 20px;
}
.add-attachment-list {
  margin: auto;
  padding: 20px 5px;
  text-align: left;
}
.web-add-attachment-dialog-content {
  display: block !important;
}
.mobile-add-attachment-dialog-content {
  display: block !important;
}
.mobile-job-detail-tab {
  padding-bottom: 20px;
}
.fuse-left-nav-icon {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}
.recaptcha-div {
  margin-bottom: 20px;
}
.trial-form-checkbox {
  position: absolute;
  float: left;
  left: 0px;
}
.trial-form-checkbox-label {
  margin-left: 45px;
}
.terms-and-conditions-div {
  margin: 20px;
}
.terms-and-condtions-link {
  text-decoration: underline !important;
  background: none !important;
}
.trial-verify-link {
  margin-bottom: 10px;
}
.trial-verify-link:hover {
  background-color: #0284c7;
}
